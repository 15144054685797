import React from "react";
import Seo from "../components/Seo/seo";

const ForYouPage = () => {
  return (
    <>
      <Seo
        title="For You"
        description="List of helpful videos to get you through life"
      />
      <div className="for-you">
        <br />
        <b>Feeling Lonely</b>
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=uYvoe6FkhL0"
              target="_blank"
              rel="noreferrer"
            >
              Loneliness vs Alone-ness, while achieving your goals in life
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=EfKbJA7HKkY"
              target="_blank"
              rel="noreferrer"
            >
              Feeling Lonely? This Might Help…
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=cKzdSY73dEk"
              target="_blank"
              rel="noreferrer"
            >
              10 Tips To Destroy Anxiety, Dissociation and Self Loathing
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=eleB3ZwG8ZM"
              target="_blank"
              rel="noreferrer"
            >
              Jocko on "It's Lonely at the Top"
            </a>
          </li>
        </ul>
        <br />
        <b>Done Something Stupid</b>
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=c5FchYZURfg"
              target="_blank"
              rel="noreferrer"
            >
              ADDICTED TO SELF-DESTRUCTION
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=Bmj19sK1yUY"
              target="_blank"
              rel="noreferrer"
            >
              Dealing with Suicidal Thoughts
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=WsNnSdruYCo"
              target="_blank"
              rel="noreferrer"
            >
              What to do if you're BROKEN
            </a>
          </li>
        </ul>
        <br />
        <b>Motivate Ya</b>
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=LZtxaPNoN18"
              target="_blank"
              rel="noreferrer"
            >
              Why We Lose Motivation
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=p33HM9NfycA"
              target="_blank"
              rel="noreferrer"
            >
              Winning Against The Odds
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=1lTcgSzf0AQ"
              target="_blank"
              rel="noreferrer"
            >
              How To Be Creative: How an Artist Turns Pro
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=sLaBgT3zE-A"
              target="_blank"
              rel="noreferrer"
            >
              The Nature of Creativity and The Courage to Create
            </a>
          </li>
        </ul>
        <br />
        <b>Misc</b>
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=hpTc3hITCic"
              target="_blank"
              rel="noreferrer"
            >
              End Procrastination FOREVER
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=6AizjmgddDo"
              target="_blank"
              rel="noreferrer"
            >
              Why Positivity Works
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=fRZFhFhR4yU"
              target="_blank"
              rel="noreferrer"
            >
              Feeling Inadequate
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=vSOcl_eZaxU"
              target="_blank"
              rel="noreferrer"
            >
              Do You Over Think?
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=YKggHAc5ZJE"
              target="_blank"
              rel="noreferrer"
            >
              I was humiliated....
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=k2ylkNkYCpk"
              target="_blank"
              rel="noreferrer"
            >
              Staying in your Comfort Zone and NOT Realizing it
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=LKIPgkiJOis"
              target="_blank"
              rel="noreferrer"
            >
              How I Became Confident | Russell Brand
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ForYouPage;
